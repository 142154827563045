import React from 'react';
import { useLocation } from '@reach/router';

import ContactPharmaMessager from 'components/contact-pharma-messager/ContactPharmaMessager';
import { useDispatch } from 'react-redux';
import {
    accountGetContactUsMessageThreadRoutine,
    accountFetchContactUsReplyMessageRoutine
} from 'state/account/account.routines';
import { messageOptions } from 'const/options';
import { navigate } from 'gatsby';

export default function ContactUs() {
    const location = useLocation();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(location.search);
    const headerId = urlParams.get('header_id');
    if (urlParams.has('header_id')) {
        dispatch(
            accountGetContactUsMessageThreadRoutine.trigger({
                HeaderID: headerId
            })
        );
        dispatch(
            accountFetchContactUsReplyMessageRoutine.trigger({
                HeaderID: headerId
            })
        );
    } else {
        if (typeof window !== 'undefined') {
            navigate('/404');
        }
    }
    return <ContactPharmaMessager messageSource={messageOptions[1].key} headerId={headerId} />;
}
